import { breakLine } from "../../helpers/break";
import VerseLight from "../../components/Verse/VerseLight";
import { useState } from "react";
import { referenceLetter } from "../../helpers/referenceLetters";

export const GetUnderTheFigTree = (data) => {
  let curIndex = 0;

  const IndexVal = () => {
    const thisIndex = curIndex;
    curIndex += 1;
    return <sup>{referenceLetter(thisIndex)}</sup>;
  };

  return (
    <div class="d-content">
      The desire to be known is something I think we can all relate to- to have
      someone who can see you for who you are and still accept you. And while we
      can experience that in part through friendships, family, marriage, there’s
      still a sense of incompleteness, there are parts of us that remain hidden
      or unseen.
      {breakLine()}
      When it comes to God, the bible makes it abundantly clear that he knows
      all there is to know about you. He knows you better than you or anybody
      else knows you.
      {breakLine()}
      There’s this interesting story at the start of John’s gospel that gives us
      a glimpse into this reality. Jesus is in the process of recruiting his
      disciples, and he’s just asked a guy named Phillip to join him, who then
      goes to his friend Nathaniel and tells him all about it. After Phillip
      approaches Nathanael, telling him Jesus is the promised one from Nazareth,
      Nathaniel makes it clear he isn’t convinced. However when he ends up
      approaching Jesus he is surprised to find out that Jesus was aware of what
      he had said earlier and Jesus had even seen him sitting under the fig
      tree- which nobody else seemed to know about. Jesus saw and knew Nathaniel
      before they had even met.
      {breakLine()}
      It’s the same for us today. Jesus sees us. He sees our flaws, our
      insecurities, our doubts, our failings, our struggles, our desires, our
      hopes, our dreams, our efforts, our hearts. He sees all of it. Psalm 139
      is an incredible psalm that says:
      <VerseLight
        text="You discern my going out and my lying down;
    you are familiar with all my ways. Before a word is on my tongue
    you, Lord, know it completely- Psalm 139:3-4"
      />
      God knows us inside out. And even in spite of our failings, through Jesus,
      he still accepts us and calls us his children. Let us rest from our vain
      attempts of earthly approval by resting in the truth that we are fully
      known and fully loved by the God of the universe.
    </div>
  );
};
