import React, { Fragment } from "react";
import "./App.css";
import Content from "./components/Content/Content";
import Landing from "./components/Landing/Landing";
import "./global.css";
import { getAllData } from "./data/main";

import { BrowserRouter, Link, Route, Routes } from "react-router-dom";

import ReactGA from "react-ga";
ReactGA.initialize("G-XD6X1LKFCS", { cookieDomain: "auto", debug: false });

const RouteComponent = (componentName) => {};

function App() {
  document.title = "Stream Of Water";

  // const [curLocation, setCurLocation] = useState("/");

  const data = getAllData().data;

  /* const pageLookup = {
    "/": <Landing />,
    "/restlessness": <Content />,
    "/gods-spiderweb": <Content />,
    "/the-skies-above": <Content />,
    "/waiting-for-the-rain": <Content />,
  }; */

  const pageLookup = {};

  Object.keys(data).forEach((key) => {
    // console.log("lookup url = ", data[key].Url);
    pageLookup[data[key].Url] = <Content />;
  });

  // useEffect(() => {
  //   setCurLocation(window.location.pathname.toString());
  // }, [window.location]);

  const DisplayPage = () => {
    console.log("Displaying page for", pageLookup);
    console.log("Pathname=", window.location.pathname);
    if (pageLookup[window.location.pathname.split("/").join("")]) {
      console.log("RETURNING CONTENT");
      return <Content />;
    } else {
      console.log("RETURNING LANDING");
      return <Landing />;
    }
  };

  return (
    <BrowserRouter>
      <div className="App">
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css?family=Tiro+Kannada"
        />
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css?family=Raleway:wght@500"
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap"
          rel="stylesheet"
        />
        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
        />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link
          href="https://fonts.googleapis.com/css2?family=Manrope:wght@300&display=swap"
          rel="stylesheet"
        />
        {Object.keys(pageLookup).map((key) => {
          return (
            <Link to={key} key={key}>
              {" "}
            </Link>
          );
        })}

        <Routes>
          <Route exact path="/" element={<Landing />} />
          <Route exact path="" element={<Landing />} />
          {Object.keys(pageLookup).map((key) => {
            return (
              <Route exact path={"/" + key} element={<Content />}>
                {" "}
              </Route>
            );
          })}
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
