import { breakLine } from "../../helpers/break";
import VerseLight from "../../components/Verse/VerseLight";

export const InItsTime = (data) => {
  return (
    <div class="d-content">
      What do you do when you’re stuck? When you’re in a rut? When you feel like
      you’ve been in the same place for ages and nothing seems to be moving? It
      can be easy to get frustrated and impatient when that’s the season of life
      you’re in. There may be plans and desires, goals or dreams that you have,
      and yet God seems to be keeping you firmly planted right where you are.
      {breakLine()}
      Have a read of this short snippet of wisdom from Ecclesiastes:
      {breakLine()}
      <VerseLight text="‘He has made everything beautiful in its time’ -Ecclesiastes 3:11a" />{" "}
      {breakLine()}
      It pretty clearly says that God is at work in everything to create beauty.
      That in the world around us, and even in our own lives God is crafting a
      beautiful masterpiece that sings to his glory.
      {breakLine()}
      The implications of this to our own lives is so profound. It means that no
      matter where you are, or what you’re facing, or what struggles you’re
      going through, God is at work to create something beautiful. But the key
      phrase here is <i>‘in its time’</i>. This isn’t something instant- the way
      God works in our lives to take us where he wants us can often be a very
      drawn out process. We see that so often in characters of the bible who are
      given years of time to wait for God to move them, like David as a
      shepherd, Jacob waiting for Rachel, or Joseph while in prison.
      {breakLine()}
      And while God ultimately will get us to where he wants us to be, the
      journey along the way can be an opportunity for us to become sweeter. To
      seek to grow in faith and dependence, patience and trust, rather than
      bitterness and envy, or anger and frustration at God’s timing. Because
      very likely, our plans won’t perfectly line up with God’s plan for us,
      consider the wisdom in Proverbs 19:
      <VerseLight text="‘Many are the plans in a person’s heart, but it is the LORD’s purpose that prevails’ -Proverbs 19:21" />{" "}
      We must have a heart of humility and surrender as we approach the fact
      that God is good and his work in our lives is to bring beauty- even if it
      doesn’t go the way we would like.
      {breakLine()}
      Ultimately however, the full revelation of God’s beauty is found in Jesus,
      as the book of Hebrews says:
      <VerseLight text="‘He is the radiance of the glory of God and the exact imprint of his nature, and he upholds the universe by the word of his power’ -Hebrews 1:3" />{" "}
      No more clearly do we see the manifestation of God working to create
      beauty with his work on the cross. It gives us a full display of his
      loving kindness and mercy. The cross shows us that the beauty that God
      works towards is beyond all of our greatest imagination and expectations.
      Will you trust that God is not only capable of, but actively working to
      create beauty, both in the world around us and in your own life too?
    </div>
  );
};
