import { breakLine } from "../../helpers/break";
import VerseLight from "../../components/Verse/VerseLight";

export const GetRain = (data) => {
  return (
    <div class="d-content">
      They’ve done quite a few studies on the impact that technology has had on
      our ability to be patient- and it’s pretty scary. They say the average
      person gets impatient if the website they’re visiting takes longer than 16
      seconds to load. You’ll likely get impatient if the traffic light takes
      longer than 25 seconds to change lights. And apparently restaurants only
      have 14 minutes before people get impatient if their food hasn’t arrived.
      As we depend more on technology and get used to efficiency- parts of life
      just get faster, and when we have to slow down and wait for things we’re
      not as used to it.
      {breakLine()}
      Being patient in more serious circumstances can be even harder, especially
      when there’s real suffering at play. Like dealing with things like
      heartache or loneliness, or waiting to hear what those medical results are
      going to be, or just when things fall apart and you have no idea what you
      are doing or what God is doing.
      {breakLine()}
      In this chapter, James firstly describes patience as being like a farmer
      who waits for the rain. It's sort of this picture of trusting God, almost
      with your life in a sense, because if the rain doesn’t come then the
      farmer’s livelihood is at risk. He won’t be able to grow the food he
      needs. God is the one who provides, we need to trust and wait on him and
      his timing.
      {breakLine()}
      We should likewise be patient and stand firm because the Lord’s coming is
      near. As we face the difficulties of life we don’t have to let them
      consume our perspective, but we can actually look forward to that final
      restoration. We can be patient because God is going to return.
      {breakLine()}
      There’s so much more that this chapter has to say about being patient,
      particularly in the context of persecution and trusting God, and so I’d
      encourage you to read through it and spend time reflecting on the patterns
      that you turn to when you face hardships.
    </div>
  );
};
