import { useState } from "react";
import "./Navbar.css";

function Navbar() {
  const [showContact, setShowContact] = useState(false);

  const handleLogoClick = () => {
    window.location.pathname = "/";
  };
  const handleContactClick = () => {
    setShowContact(!showContact);
  };

  return (
    <nav class="nav">
      <div class="nav-left">
        <div
          class="logo"
          style={{ filter: "grayscale(50%)", cursor: "pointer" }}
          onClick={() => handleLogoClick()}
        ></div>
      </div>
      <div class="right-nav">
        {/* <div
          class="nav-link-about"
          style={{ cursor: "pointer" }}
          onClick={() => handleContactClick()}
        >
          <b class="contact-button">Contact</b>
        </div> */}
        {showContact && (
          <div class="nav-link-contact">
            You can reach us at contactstreamofwater@gmail.com
          </div>
        )}
      </div>
    </nav>
  );
}

export default Navbar;
