import { breakLine } from "../../helpers/break";
import { BulletList } from "../../components/List/List";
import VerseLight from "../../components/Verse/VerseLight";
import { referenceLetter } from "../../helpers/referenceLetters";

export const GetRest = (data) => {
  let curIndex = 0;
  const IndexVal = () => {
    const thisIndex = curIndex;
    curIndex += 1;
    return <sup>{referenceLetter(thisIndex)}</sup>;
  };
  return (
    <div class="d-content">
      The pace of life can vary in so many ways. Uneventful seasons of slowness
      can almost suddenly be surpassed by moments of overwhelm- good or bad. In
      those fast-paced and eventful moments, things can often be exciting and
      bright, or perhaps bring upon feelings of inadequacy and fear.
      {breakLine()}I wonder how you cope in those moments. In those moments when
      life is overwhelming and everything seems to be happening at once. What do
      you turn to? Who do you turn to?
      {breakLine()}
      Psalm 62 says that in those moments, the call is for us to find rest. And
      to find rest in God. This Psalm describes someone who’s world is being
      turned upside down. They’re thrown down, attacked, assaulted and spoken
      ill of by those around them. And yet amidst all of that, they choose to
      hand it over to God, to trust in him and turn to him. To accept his
      sovereign control and authority over the heartache that is ensuing.
      {breakLine()}
      The Psalmists’ attitude towards God allows them to rest in the reality
      that God is a fortress, a rock that can’t be moved, to see him as one who
      gives an unshakable security. Not only this, but the Psalmist’s means of
      finding rest is also described by an action, one of prayer, where it says:
      <VerseLight text="‘Trust in him at all times, you people; pour your hearts to him, for God is our refuge’ (v8)" />
      That as we rest in God and trust in him, we intertwine that with prayer as
      a tangible and lived out reality of our dependence on God.
      {breakLine()}
      How incredible is that! This is the God of the universe, the one who made
      everything- the stars, the moon, the seasons, plants and animals. Despite
      his majesty, he stoops down to our level and is willing to listen to us.
      And not just to our regular old prayers, the ones we get into a rhythm of
      and say without any real thought. He actually invites us to pour our
      hearts out to him. To speak to him and let him know how we’re feeling,
      what we’re going through, what we’re struggling with, what we need help
      with, what we’re thankful for, how we can better serve him. Is that the
      way we pray? Is that the way we speak to God?
      {breakLine()}
      Because of Jesus, we can have the privilege of being able to approach God
      in this way. And so we’re invited to pour our hearts out to him, with all
      our fears and anxieties, insecurities and doubts, heartache and pain. God
      sees it all, and when you pray to him, he’s there to listen to you. Be
      encouraged to know that you can approach God with anything and everything
      you’re going through and that he’ll be there to listen. That in him, and
      only in him, can we find true rest.
    </div>
  );
};
