import { breakLine } from "../../helpers/break";

export const GetSpiderweb = (data) => {
  return (
    <div class="d-content">
      As it starts to get warmer heading into spring, I’ve noticed a lot of
      spiders starting to make their way out into our house and backyard. You
      have to be careful with all the webs that they start forming and make sure
      you don’t accidentally walk through one- it’s one of the worst feelings
      when you have to scrape webbing and dead insects off of yourself. But
      spiderwebs, when you’re not covered in them, are really quite beautiful to
      look at- they’re intricate and detailed, delicate but yet structured, and
      every single strand serves a purpose in creating their shape.
      {breakLine()}I think the way God works in our lives is a bit like a
      spiderweb. He’s intentional and precise with everything that’s going on,
      with the friends and family around you, with school or work, even with all
      the mundane day to day things that happen in your life- God is in it all.
      Like the strands of a spiderweb each aspect of your life is being
      purposefully placed according to his will. In fact, Romans 8:28a says “And
      we know that in all things God works for the good of those who love him”.
      {breakLine()}
      But sometimes it doesn’t always feel like that- sometimes life gets messy,
      things get difficult, and it’s easy to doubt whether God sees or even
      cares about what’s happening. It’s important to remind ourselves that God
      is not absent, even in those moments. Just like when you can't see that
      spiderweb, it doesn't mean it's not there or that it's any less beautiful.
      {breakLine()}
      When we look at the cross and see how God worked through a seemingly dark
      and hopeless situation to bring about our salvation, we can be reminded
      that he loves us deeply and is perfectly capable and trustworthy with how
      he is at work in our lives. How are you going with trusting God with what
      he’s doing in your life? How could you better depend and rely on him? I'd
      encourage you to check out the rest of Romans 8 to read more about how God
      works in our lives.
    </div>
  );
};
