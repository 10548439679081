import { breakLine } from "../../helpers/break";
import VerseLight from "../../components/Verse/VerseLight";

export const GetUndeserved = (data) => {
  return (
    <div class="d-content">
      Tax collectors were the outcasts of Jesus’ day. They were seen as people
      who betrayed their own to go and serve the Romans. They were disliked,
      shunned, and even cut off from the core of Jewish society. A tax collector
      was someone you wouldn’t want to be seen with, you would avoid them and
      stay clear of them as much as possible.
      {breakLine()}
      The interaction between Jesus and Levi the tax collector however shows
      just how vast God’s love is. While passing through a crowd of people,
      Jesus sees Levi and plainly asks him to follow him, to which Levi suddenly
      does- an incredible response of faith. But we see Jesus’ heart in this.
      Jesus actually wants Levi on his side, he doesn’t care about his history,
      his baggage, his social standing, or his past choices. Jesus sees all of
      Levi and loves him anyway.
      {breakLine()}
      Reading this story we might be tempted to place ourselves as the disciples
      who are already following Jesus- morally upright and watching to see if
      this tax collector will take Jesus up on his offer. However the truth is
      we’re more like Levi. We’re broken sinners who have been made outcast in
      our relationship with God, we’ve betrayed him and he has every right to
      avoid us and see us with disdain.
      {breakLine()}
      But just like Jesus’ heart towards Levi, his heart towards us is the same.
      He sees us with all our flaws and all our mess, and yet he invites us in
      anyway. He sits and eats with sinners and tax collectors. He reaches out
      to you, he loves you and wants you to be with him. If we humbly accept our
      need for him, then he is all ours. Following Jesus is not a past choice
      but a daily reality, will you see his love for you and follow him today?
    </div>
  );
};
