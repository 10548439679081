import "./Landing.css";
import Latest from "./Latest";
import Navbar from "../Navbar";
import { Footer } from "../Footer";

function Landing() {
  //const reader = new FileReader();
  //const text = reader.readAsText(content);
  document.title = "Stream Of Water";

  return (
    <div>
      <div class="main-container">
        <Navbar />
        <div class="l-image">
          <div class="l-title" unselectable="on"></div>
        </div>
        <Latest></Latest>
      </div>
    </div>
  );
}

export default Landing;
