const bookLookupTable = {
  Genesis: "Gen",
  Exodus: "Ex",
  Leviticus: "Lev",
  Numbers: "Num",
  Deuteronomy: "Deut",
  Joshua: "Josh",
  Judges: "Judg",
  Ruth: "Ruth",
  "1 Samuel": "1 Sam",
  "2 Samuel": "2 Sam",
  "1 Kings": "1 Kgs",
  "2 Kings": "2 Kgs",
  "1 Chronicles": "1 Chr",
  "2 Chronicles": "2 Chr",
  Ezra: "Ezra",
  Nehemiah: "Neh",
  Esther: "Esth",
  Job: "Job",
  Psalms: "Psalm",
  Psalm: "Psalm",
  Proverbs: "Prov",
  Ecclesiastes: "Eccl",
  "Song of Songs": "Song",
  Isaiah: "Isa",
  Jeremiah: "Jeremiah",
  Lamentations: "Lam",
  Ezekiel: "Ezek",
  Daniel: "Dan",
  Hosea: "Hos",
  Joel: "Joel",
  Amos: "Amos",
  Obadiah: "Obad",
  Jonah: "Jonah",
  Micah: "Mic",
  Nahum: "Nah",
  Habakkuk: "Hab",
  Zephaniah: "Zeph",
  Haggai: "Hag",
  Zechariah: "Zech",
  Malachi: "Mal",

  Matthew: "Matt",
  Mark: "Mark",
  Luke: "Luke",
  John: "John",
  Acts: "Acts",
  Romans: "Rom",
  "1 Corinthians": "1 Cor",
  "2 Corinthians": "2 Cor",
  Galatians: "Gal",
  Ephesians: "Eph",
  Philippians: "Phil",
  Colossians: "Col",
  "1 Thessalonians": "1 Thess",
  "2 Thessalonians": "2 Thess",
  "1 Timothy": "1 Tim",
  "2 Timothy": "2 Tim",
  Titus: "Titus",
  Philemon: "Phlm",
  Hebrews: "Heb",
  James: "Jas",
  "1 Peter": "1 Pet",
  "2 Peter": "2 Pet",
  "1 John": "1 John",
  "2 John": "2 John",
  "3 John": "3 John",
  Jude: "Jude",
  Revelation: "Rev",
};

export const getShorterBook = (book) => {
  const passageSplit = book.split(" ");
  let passageWithNumber = passageSplit[0];
  console.log("Got passage split", passageSplit);
  let hasStartNum = false;
  if (passageSplit.length > 2) {
    console.log("Doing slice", passageSplit, passageSplit.slice(0, 2));
    passageWithNumber = passageSplit.slice(0, 2);
    hasStartNum = true;
  } else {
    passageWithNumber = passageSplit[0];
  }
  let passage = passageWithNumber[0];
  if (!isNaN(passageWithNumber[0])) {
    console.log("Has number", passageWithNumber);
    passage = passageWithNumber[0] + " " + passageWithNumber[1];
  } else {
    passage = passageWithNumber;
  }

  console.log("Got passagef", passage);
  const shorterPassage = bookLookupTable[passage];
  let returnBook = shorterPassage + " " + passageSplit[passageSplit.length - 1];

  console.log("Return book=", returnBook);
  return returnBook;
};
