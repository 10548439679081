import { breakLine } from "../../helpers/break";
import VerseLight from "../../components/Verse/VerseLight";

export const GetTimeAndChance = (data) => {
  return (
    <div class="d-content">
      The book of Ecclesiastes gives both a challenging but honest view of life
      under the sun- a perspective where God is either cut out of the picture or
      at best distant and indifferent to life’s realities. As the teacher, the
      one who is giving these observations under the sun, explores many
      different avenues of life, we arrive at Chapter 9 where we come across
      this bleak but encompassing verse of what he has explored:
      <VerseLight text="‘‘I have seen something else under the sun: The race is not to the swift or the battle to the strong, nor does food come to the wise or wealth to the brilliant or favor to the learned; but time and chance happen to them all.’ (v11)" />{" "}
      I think we can all relate to what the teacher is stressing here, life
      isn’t neat and formulaic, so often it can be unpredictable and
      frustrating. You can do all the right things, eat healthy and exercise,
      treat people nicely, and contribute to society in a positive way, but that
      is in no way a guarantee that your life will go well and you’ll be
      successful.
      {breakLine()}
      Even in the case of those who do seem to be at the top and have life
      working well for them, the sobering reality of death looms over all.
      <VerseLight text="‘‘Moreover, no one knows when their hour will come: as fish are caught in a cruel net, or birds are taken in a snare, so people are trapped by evil times that fall unexpectedly upon them.’’ (v12)" />{" "}
      When we sit under and stay in this perspective that the teacher of
      Ecclesiastes is describing to us we can be prone to anxiety and doubt. We
      are left questioning the ultimate purposes of our suffering and
      sacrifices. We are left without hope for when death reaches us at the end.
      {breakLine()}
      However, when we step back and read the bible as a whole, we can remember
      that our lives aren't’ purely under the sun. There is in fact a creator
      who made the sun, a creator who stoops to our level and sees us. And that
      Jesus, our creator, actually stepped into and felt the frustrations of
      life all the way to the cross so that we might know him.
      {breakLine()}
      Time and chance do not rule over our lives. We have a God who is
      intimately and intricately involved with every detail and every moment. We
      can trust him, and trust his purposes, knowing that he is in total control
      of everything that happens under the sun.
    </div>
  );
};
