import { breakLine } from "../../helpers/break";
import VerseLight from "../../components/Verse/VerseLight";

export const GetStreams = (data) => {
  return (
    <div class="d-content">
      Psalm 1 says that God’s law, or his word, is essential to our lives. That
      if we’re like a plant, then his word is the water we need to grow and be
      sustained. By reading and sitting under God’s word we then become be like
      a tree planted right next to streams of water, constantly supplied and
      nourished with all that we need.
      {breakLine()}
      The bible, as we have it today, is full of so much wisdom, guidance and
      stories that describe how God has worked in history to redeem his people
      through the work of Jesus. However the bible is more than just a book,
      it’s God’s way of reaching out to us and actually speaking to us
      personally and individually. Whenever we read God’s word, the Holy Spirit
      is at work helping us to understand it and be changed by it. But without
      God’s word we’re prone to wither, our roots become weak and we can lose
      our foundation.
      <VerseLight text="‘Not so the wicked! They are like chaff that the wind blows away. (v4)’" />
      I think we can easily underestimate the power of God’s word in our life
      and its ability to change and strengthen us. Maybe we fall into rhythmic
      patterns and read it just because we know we should, rather than read it
      with excitement and joy. Maybe we avoid it because it might feel dry or
      difficult to understand at times.
      {breakLine()}
      Pray that we might better appreciate God’s word for what it is and what it
      is capable of doing, that it might become sweeter to us each day. That
      through his word God will mature and transform us. That we might be like
      trees planted by streams of water and slowly become more like Jesus as we
      yield fruit for his kingdom.
    </div>
  );
};
