export const BulletList = ({ items }) => {
  return (
    <div style={{ width: "90%", textAlign: "left" }}>
      <ul>
        {items.map((item) => {
          return <li key={item}>{item}</li>;
        })}
      </ul>
    </div>
  );
};
