import "./Content.css";
import Verse from "../Verse/Verse";
import Navbar from "../Navbar";
import { Footer } from "../Footer";
import { getAllData } from "../../data/main";
import React, { useEffect } from "react";
import { referenceLetter } from "../../helpers/referenceLetters";
// const data =  require('../../data/restless.json');
import useWindowDimensions from "../../helpers/useWindowDimensions";
import { getBookCol } from "../../helpers/bookColours";

function Content() {
  //const reader = new FileReader();
  //const text = reader.readAsText(content);
  const { width, height } = useWindowDimensions();

  const DATE_CUTOFF = 560;
  const isLarge = width >= DATE_CUTOFF;

  const globalData = getAllData();
  // console.log("In content with path", window.location.pathname);
  const contentRequested = window.location.pathname.split("/").join("");

  if (!globalData.data[contentRequested]) {
    // window.location.pathname = "/";
  }

  const data = globalData.data[contentRequested];
  // console.log("Got data", data);
  const Body = data.Body;

  const getLinkHref = () => {
    return `https://www.biblegateway.com/passage/?search=${data.Passage}&version=NIV`;
  };

  const openSourceLink = () => {
    const sLink = getLinkHref();
    window.open(sLink, "_blank");
  };

  document.title = `${data.Passage}: ${data.Title}`;

  const showEndVerse = data.Verse.length > 0;

  let imgPercentage = 90;

  if (data.ImgPercentage) {
    imgPercentage = data.ImgPercentage;
    // console.log("Got percentage as", imgPercentage);
  }

  const component = (
    <div>
      <title>{data.Title}</title>
      <meta name="overview" content={data.Synopsis} />
      <meta name="passage" content={data.Passage} />
      <meta name="keywords" content={data.Keywords} />
      <Navbar></Navbar>
      <div class="content-container">
        <div class="d-passage">
          <div
            class="d-passage-box"
            style={{
              backgroundColor: data.Col, // getBookCol(data.Passage),
              borderColor: data.Col, //getBookCol(data.Passage),
            }}
            onClick={() => {
              openSourceLink();
            }}
          >
            {data.PassageDetailed || data.Passage}
          </div>
        </div>
        {isLarge && <div class="d-title">{data.Title}</div>}
        {!isLarge && <div class="d-title">{data.Title}</div>}
        <div class="d-short-desc">
          {isLarge ? `${data.OneLiner} | ${data.Date}` : `${data.OneLiner}`}
        </div>
        <div
          class="d-image"
          style={{
            backgroundImage: `${data.ImgUrl}`,
            backgroundPosition: `0% ${imgPercentage}%`,
          }}
        ></div>

        {data.ImageReference && data.ImageReferenceText && (
          <span>
            <a
              class="d-image-reference"
              target="_blank"
              href={data.ImageReference}
            >
              {data.ImageReferenceText}
            </a>
          </span>
        )}

        <div>
          {Body && <Body data={data} />}
          {showEndVerse && (
            <Verse text={`"${data.VerseInfo}"`} verse={data.Verse}></Verse>
          )}
        </div>
        <div class="d-references">
          {data.References &&
            data?.References.map((val, index) => {
              console.log("Got reference value", val);
              return (
                <div>
                  {referenceLetter(index)} : {val}{" "}
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );

  /* const isMounted = useRef(false)

  useEffect(() => {
    isMounted.current = true;
    return () => { isMounted.current = false }
  }, []); */

  return component;
}

export default Content;
