import { breakLine } from "../../helpers/break";
import VerseLight from "../../components/Verse/VerseLight";

export const GetSkies = (data) => {
  return (
    <div class="d-content">
      My shadow is barely visible with each step. I pass through the dimly lit
      streets with the faint howling of the wind behind me. The grass moves in
      waves as I walk through an open field, each blade softly glistening. The
      moon’s intense glow echoes rays of light as it stares back at me. The
      stars above flicker, it’s like they’re whispering to each other, deep in
      conversation as they share their secrets. I keep looking and more stars
      appear, flickering and shining as they join in the praise. Like scattered
      glitter the night sky slowly lights up, their whispers turn to voices
      which turn to singing. I see just how small and insignificant I am, yet he
      chooses to look at me, to be with me, to even love me.
      <VerseLight text="‘Day after day they pour forth speech; night after night they reveal knowledge (v2)’" />
      The chirping of birds fills the air as the leaves rustle in the gentle
      breeze. Each tree moves in its own rhythm, swaying side to side as
      scattered rays from the sun illuminate them. They point up to the sky
      where I see the clouds. All unique in shape, yet equally captivating.
      Their backdrop is a deep blue which is radiated by the sun. Its light is
      everywhere, filling every crevice and warming all that is around. It
      reminds me of his love, seeking out and pursuing me even when I run from
      him.
      <VerseLight text="‘It rises at one end of the heavens and makes its circuit to the other; nothing is deprived of its warmth (v6)’" />
      Through Psalm 19 we can see some ways in which we can better know God, one
      of them being through the world around us. God reveals some of himself in
      the beauty of a sunset, the depths of space above, or the gentle crashing
      of waves on a beach. I think part of Psalm 19 is encouraging us to be more
      attentive, to willingly take time to look at the details and ponder the
      beauty that is all around us. Creation points to God, it sings to him, and
      through it we can start to see more of his glory.
    </div>
  );
};
