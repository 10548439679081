import { breakLine } from "../../helpers/break";
import VerseLight from "../../components/Verse/VerseLight";

export const GetHoldingOn = (data) => {
  return (
    <div class="d-content">
      So often the way we look and what we find our bodies to be capable of can
      be how we define our value. We limit our self-worth to the external, to
      what is on the outside. And perhaps for some, this may be (albeit
      temporarily) a source of immense self-worth. However over time we quickly
      discover that our bodies do not last. They break down and fall apart.
      Wounds, bruises, scars, broken bones, aging, they all remind us that we’re
      fallible and time-bound. We have an expiry date.
      {breakLine()}The Christian’s response to this is to persevere. To endure
      through the difficulties that our bodies and the difficulties that this
      earthly life gives us, and to rather look to the mechanics of what Christ
      is doing in us inwardly. Paul makes this abundantly clear to the
      Corinthians as he describes our bodies as being like jars of clay that
      contain the rich treasure of knowing Jesus as Lord and the glory of God
      displayed in Jesus.
      <VerseLight
        text="‘For what we preach is not ourselves, but Jesus Christ as Lord, 
      and ourselves as your servants for Jesus’ sake. 
      For God, who said, “Let light shine out of darkness,” made his light shine in our 
      hearts to give us the light of the knowledge of God’s glory displayed in the face 
      of Christ.’ - 2 Corinthians 4:5-6"
      />
      And that even in the weakness of our bodies, the beauty of this message
      will be highlighted because of its contrast to our weaknesses.
      <VerseLight
        text="‘But we have this treasure in jars of clay to show that this 
      all-surpassing power is from God and not from us.’ - 2 Corinthians 4:7"
      />
      And so there is this element to which our bodily failings are able to be
      used by God for his glory.
      {breakLine()}
      In this dynamic struggle, Paul quotes Psalm 116:10 which says ‘I believed;
      therefore I have spoken’ and then says that we are able to have this same
      spirit of faith. Reading the rest of Psalm 116 shows us that this type of
      spirit is a spirit that is wholly dependent on God (Ps 116:6), a spirit
      that turns to him and calls out to him (Ps 116:1), a spirit that trusts
      that he hears you (Ps 116:2), a spirit that isn’t resentful when faced
      with distress or pain, but rather one that reflects on all the mercy and
      grace that he has already poured out (Ps 116:5-7), and even a spirit that
      is thankful (Ps 116:12-14).
      {breakLine()}
      There is real beauty in the spirit that this psalmist has, and I pray that
      it might be the attitude I grow to live with more in my own life. However
      we must admit that it can be easy to be cavalier or insensitive in saying
      this is how we or how we should expect others to live in the face of
      physical troubles- the practicality of living it out isn’t always as
      simple. Our bodies are what we get stuck with our whole lives, we can’t
      escape them. And perhaps there are things about your body that cause you
      grief whether physically or otherwise. Sickness that won’t heal, pain that
      won’t go away, degradation of capability. The reality is, it is hard to
      live with that.
      {breakLine()}
      And so Paul encourages us to remember that we can have hope because God is
      at work in all of these struggles. That in our suffering and brokenness,
      he is renewing us and through it achieving for us an eternal glory- our
      jars of clay are actually used in the plans of God! A truth that is so
      important for us to sit under that we should fix our eyes on it and
      constantly remind ourselves of it. This temporary life isn’t all there is
      to us, God’s kingdom and its often unseen beauty is the only thing that
      will last.
      {breakLine()}
      Any momentary suffering, of your body or of any other circumstances, will
      one day be made so weak and incomparable to the greatness of what lies
      ahead in the resurrection. But in the meantime, let us read through the
      psalmists' words in Psalm 116 and seek to have that same spirit of faith.
      Whatever disposition you find your body to be in, pray that you might hold
      onto this truth.
    </div>
  );
};
