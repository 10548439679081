import { breakLine } from "../../helpers/break";
import VerseLight from "../../components/Verse/VerseLight";
import { useState } from "react";
import { referenceLetter } from "../../helpers/referenceLetters";

export const GetLonelyPlaces = (data) => {
  let curIndex = 0;

  const IndexVal = () => {
    const thisIndex = curIndex;
    curIndex += 1;
    return <sup>{referenceLetter(thisIndex)}</sup>;
  };

  return (
    <div class="d-content">
      In the early stages of Jesus’ ministry we quite often see him withdraw
      from crowds and head into quieter regions <IndexVal />. This initially
      seems strange and raises various questions as to his motive behind this.
      {breakLine()}
      The gospels seem to give us a few reasons as to why Jesus does this on
      certain occasions. One being that there were crowds who wanted to make him
      king by force
      <IndexVal /> due to their misunderstanding of what the Messiah would do
      and accomplish. Another being in the case where his friend John the
      Baptist was killed and he wanted to step back and spend time away from the
      crowds to rest
      <IndexVal />. And another being that the Jews wanted to kill him
      prematurely, which Jesus wanted to avoid until his proper time.
      <IndexVal />.{breakLine()}
      All of these reasons have a lot to teach us about Jesus. They show us his
      humility as he submits to and fulfills scripture. They show us how he was
      touched by the reality of sin and death. They show us Jesus’ dependance on
      the Father as he continually turns to him in prayer. They also show us
      that Jesus’ willingness to submit to the Father wasn’t just an event at
      the cross, but rather an occurring call throughout his entire ministry.
      {breakLine()}
      As Jesus heads closer to the cross, his isolation increases. At Gethsemane
      he even withdraws from his own disciples as he prepares for what lies
      ahead.
      <VerseLight text="‘He withdrew about a stone's throw beyond them, knelt down and prayed’ - Luke 22:41" />
      The last withdrawal Jesus faces is by the Father on the cross, where he is
      forsaken in his final moments.
      {breakLine()}I don’t think we’ll ever comprehend the depths of the
      isolation and loneliness Jesus felt throughout his life and on the cross.
      But we can know that what he did endure, he did out of love for us.
      {breakLine()}
      Jesus enables us to be in relationship with God. Because of Jesus we can
      pray and be heard, because of Jesus God will never withdraw himself from
      us, because of Jesus we never have to be lonely again.
    </div>
  );
};
