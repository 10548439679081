import { breakLine } from "../../helpers/break";
import VerseLight from "../../components/Verse/VerseLight";

export const GetEternityBeyond = (data) => {
  return (
    <div class="d-content">
      So often I find myself getting overly caught up in the day-to-day.
      Focusing on the details of the here and now and putting all my attention
      into what’s immediate. While this is important to do at times, I think
      it’s important to regularly step back and view our lives from an eternal
      perspective.
      {breakLine()}
      Difficult seasons of life can also sometimes distract us from the eternal
      perspective. The pain of heartache, loneliness, or broken relationships
      can quite often consume our view and cause us to forget about what lies
      ahead.
      {breakLine()}
      However the bible doesn’t shy away from reminding us how short this life
      is. Psalm 39, for example, describes our life as being almost as short as
      a breath
      <VerseLight
        text="‘‘You have made my days a mere handbreadth;
      the span of my years is as nothing before you.
      Everyone is but a breath,
      even those who seem secure’ Psalm 39:5"
      />{" "}
      {breakLine()}
      Or James writes about how there’s so much uncertainty to life, especially
      regarding what we do with the little time we have in it.
      <VerseLight
        text="‘‘Now listen, you who say, 
      “Today or tomorrow we will go to this or that city, 
      spend a year there, carry on business and make money.” 
      Why, you do not even know what will happen tomorrow. 
      What is your life? You are a mist that appears for a 
      little while and then vanishes.’ James 4:13-14"
      />{" "}
      {breakLine()}
      Jesus likewise makes it clear that we should be concerned with storing up
      treasures in heaven rather than treasures on earth which we only have a
      short amount of time to hold onto.
      <VerseLight
        text="‘‘Do not store up for yourselves treasures on earth, where 
      moths and vermin destroy, and where thieves break in and steal. ’ Matthew 6:19"
      />{" "}
      In comparison to all of eternity, the lives we live now are incredibly
      short. Are we living for what matters? Are we living for things that will
      last? Things that have eternal value? Things that aren’t limited to just
      this life? Things that can further His kingdom and bring people into it?
      {breakLine()}
      How might you be seeking to further serve and live for God in your current
      context? I’m quite often scared of how my non-christian friends will
      respond or think about my faith rather than being more concerned about how
      God sees me. I hope we’re encouraged to see that we can be bold and we can
      take risks for his kingdom’s sake because we are safe in the eternal
      security that Jesus has already won for us through his work on the cross.
    </div>
  );
};
