import { breakLine } from "../../helpers/break";
import VerseLight from "../../components/Verse/VerseLight";
import { useState } from "react";
import { referenceLetter } from "../../helpers/referenceLetters";

export const JoyForAll = (data) => {
  let curIndex = 0;

  const IndexVal = () => {
    const thisIndex = curIndex;
    curIndex += 1;
    return <sup>{referenceLetter(thisIndex)}</sup>;
  };

  return (
    <div class="d-content">
      When was the last time you felt genuine joy? What caused it? How long did
      it last? Do you still feel that same joy looking back at that experience?
      {breakLine()}
      In the gospel of Luke, part of the account of Jesus’ birth takes us to a
      few shepherds who are out working in the fields. Suddenly an angel appears
      to them with the glory of the Lord, and their natural response is one of
      terror and fear. They’re afraid of the holiness and glory that God’s
      presence is displayed in and through this angel.
      {breakLine()}
      The shepherd’s response is the response we would all naturally have. Ever
      since the fall, our relationship with God has been broken and so our
      ability to approach him has been ruined. Our sin and his holiness aren’t
      compatible. His very presence is so pure that we can only respond with
      withdrawal and fear- even a terror of God’s judgement and justice that we
      all deserve.
      <VerseLight text="‘ But the angel said to them, “Do not be afraid. I bring you good news that will cause great joy for all the people. Today in the town of David a Savior has been born to you; he is the Messiah, the Lord. This will be a sign to you: You will find a baby wrapped in cloths and lying in a manger.”’ - Luke 2:10-12" />
      {breakLine()}
      The story of Christmas is that Jesus stepped down into our world. That he
      became a human to accomplish on that cross what none of us could ever do.
      That through his life, death, and resurrection we might find freedom from
      our sin and the fear that it causes in us. That because of his work, we
      can freely approach God without being afraid or terrified.
      {breakLine()}
      As we celebrate the Christmas season, let us be reminded that Jesus’ birth
      means we can have joy. And not just any joy, but a joy that is everlasting
      and eternal. A joy that doesn’t weaken over time. A joy that is caused by
      our saviour. A joy that is great.
    </div>
  );
};
