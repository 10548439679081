import React from "react";

import "./index.css";
import App from "./App";
import { render } from "react-snapshot";
import reportWebVitals from "./reportWebVitals";

/* const rootElement = document.getElementById("root");
if  (rootElement.hasChildNodes()) {
  hydrate(<App />, rootElement);
} else {
  render(<App />, rootElement);
} 
render(<App />, document.getElementById("root"));*/

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

// const rootElement = document.getElementById("root");

render(<App />, document.getElementById("root"));
reportWebVitals();

/* if (rootElement.hasChildNodes()) {
  ReactDOM.hydrate(<App />, rootElement);
} else {
  ReactDOM.render(<App />, rootElement);
} */
