import { GetDelight } from "./BodyData/delight";
import { GetSpiderweb } from "./BodyData/gods-spiderweb";
import { GetRestlesssness } from "./BodyData/restlessness";
import { GetSkies } from "./BodyData/the-skies-above";
import { GetRain } from "./BodyData/waiting-for-the-rain";
import { GetStreams } from "./BodyData/streams-of-water";
import { GetUndeserved } from "./BodyData/an-undeserved-call";
import { GetShade } from "./BodyData/shade";
import { GetLonelyPlaces } from "./BodyData/lonely-places";
import { GetUnderTheFigTree } from "./BodyData/under-the-fig-tree";
import { GetPleasantPlaces } from "./BodyData/pleasant-places";
import { GetTimeAndChance } from "./BodyData/time-and-chance";
import { GetEternityBeyond } from "./BodyData/eternity-beyond";
import { JoyForAll } from "./BodyData/joy-for-all";
import { GetHoldingOn } from "./BodyData/holding-on";
import { GetAloneAndForgotten } from "./BodyData/alone-and-forgotten";
import { InItsTime } from "./BodyData/in-its-time";
import { GetQuietWaters } from "./BodyData/quiet-waters";
import { GetAGodWhoSees } from "./BodyData/a-god-who-sees";
import { GetRest } from "./BodyData/rest";

export const getAllData = () => {
  return {
    data: {
      /* delight: {
        Url: "delight",
        Title: "Delight",
        Passage: "Micah 7",
        Image: "clouds.jpg",
        VerseInfo: "Your ways, God, are holy. What god is as great as our God?",
        OneLiner: "The kind of God He is",
        Synopsis: "...",
        Verse: "Micah 7:18",
        Date: "July 2022",
        ImgUrl: `url(${require(`../images/bloom2.jpg`)})`,
        Body: GetDelight,
      }, */
      /* "under-the-sun": {
        Url: "under-the-sun",
        Title: "Under the Sun",
        Passage: "Ecclesiastes 1",
        OneLiner: "Forsaken for our sake",
        VerseInfo: "But Jesus often withdrew to lonely places and prayed.",
        Synopsis:
          "There was a loneliness and isolation that Jesus experienced during his human life. What does it teach us?",
        Verse: "Luke 5:16",
        Date: "29th August 2022",
        ImgUrl: `url(${require(`../images/dark_sunset.jpeg`)})`,
        ImgPercentage: 60,
        Keywords: "Shade, Psalm 121, Protection, Guidance",
        References: [
          "Matthew 12:15, Matthew 14:13, Mathew:15:21, Mark 3:7, Luke 5:16, Luke 9:10, John 6:15, John 11:54",
          "John 6:15",
          "Matthew 12:14",
        ],
        Body: GetLonelyPlaces,
      }, */
      rest: {
        Url: "rest",
        Title: "Rest",
        Passage: "Psalm 62",
        OneLiner: "For when it's overwhelming",
        VerseInfo: "Yes, my soul, find rest in God; my hope comes from.",
        Synopsis:
          "Who or what do we turn to when life is overwhelming? True rest in such moments is found only through trust and prayer in God.",
        Verse: "Psalm 62:1",
        Date: "10th Jul 2023",
        ImgUrl: `url(${require(`../images/Rest.jpg`)})`,
        ImgPercentage: 60,
        Keywords: "Broken body, 2 Corinthians 4, Pain, Trust, Renewed",
        References: [],
        Body: GetRest,
        ImageReference: "https://www.instagram.com/brodie_imagery/",
        ImageReferenceText: "Photo by @brodie_imagery",
        Col: "#abb3af",
      },
      "in-its-time": {
        Url: "in-its-time",
        Title: "In Its Time",
        Passage: "Ecclesiastes 3",
        OneLiner: "For when you feel stuck",
        VerseInfo: "He has made everything beautiful in its time.",
        Synopsis:
          "We can often feel stuck and unsure when our plans and desires don't come to fruition. How should we respond in those moments?",
        Verse: "Ecclesiastes 3:11a",
        Date: "18th Apr 2023",
        ImgUrl: `url(${require(`../images/hidden-min.jpg`)})`,
        ImgPercentage: 48,
        Keywords: "Broken body, 2 Corinthians 4, Pain, Trust, Renewed",
        References: [],
        Body: InItsTime,
        ImageReference: "https://www.instagram.com/brodie_imagery/",
        ImageReferenceText: "Photo by @brodie_imagery",
        Col: "#f1d8f2",
      },
      "quiet-waters": {
        Url: "quiet-waters",
        Title: "Quiet Waters",
        Passage: "Mark 6",
        OneLiner: "For when you feel lost and unsure",
        VerseInfo: "They all ate and were satisfied",
        Synopsis:
          "Our God is a God who shepherds us. He leads us and guides us according to his perfect and good will.",
        Verse: "Mark 6:42 ",
        Date: "24th Jan 2023",
        ImgUrl: `url(${require(`../images/waters-still.jpg`)})`,
        ImgPercentage: 60,
        Keywords: "Broken body, 2 Corinthians 4, Pain, Trust, Renewed",
        References: [
          "Mark 6:31",
          "Mark 6:34",
          "Mark 6:34",
          "Mark 6:34",
          "Mark 6:39",
          "Mark 6:31",
          "Mark 6:42",
        ],
        Body: GetQuietWaters,
        Col: "#d3dbdb",
      },
      "a-god-who-sees": {
        Url: "a-god-who-sees",
        Title: "A God Who Sees",
        Passage: "Genesis 16",
        OneLiner: "For when you feel like no one sees you",
        VerseInfo:
          "She gave this name to the Lord who spoke to her: “You are the God who sees me,” for she said, “I have now seen the One who sees me.",
        Synopsis:
          "The story of Hagar shows us that we are never alone. God sees us in our hurts, our miseries, and even when we feel forgotten.",
        Verse: "Genesis 16:13",
        Date: "7th Jan 2023",
        ImgUrl: `url(${require(`../images/grass-wilderness.jpg`)})`,
        ImgPercentage: 60,
        Keywords: "Broken body, 2 Corinthians 4, Pain, Trust, Renewed",
        References: [],
        Body: GetAGodWhoSees,
        Col: "#d9d0c3",
      },
      "holding-on": {
        Url: "holding-on",
        Title: "Holding On",
        Passage: "2 Corinthians 4",
        OneLiner: "For when you struggle with self-image",
        VerseInfo:
          "So we fix our eyes not on what is seen, but on what is unseen, since what is seen is temporary, but what is unseen is eternal.",
        Synopsis:
          "The limitations and brokenness of our bodies can often be frustrating. We are reminded to hold on by remembering Jesus as Lord.",
        Verse: "2 Corinthians 4:18 ",
        Date: "31st Dec 2022",
        ImgUrl: `url(${require(`../images/boat.jpg`)})`,
        ImgPercentage: 60,
        Keywords: "Broken body, 2 Corinthians 4, Pain, Trust, Renewed",
        References: [],
        Body: GetHoldingOn,
        Col: "#e3e0c5",
      },
      "joy-for-all": {
        Url: "joy-for-all",
        Title: "Joy For All",
        Passage: "Luke 2",
        OneLiner: "For when you feel joyless",
        VerseInfo:
          "But the angel said to them, “Do not be afraid. I bring you good news that will cause great joy for all the people",
        Synopsis:
          "Jesus' birth means that we can have real and genuine joy as we listen to the good news of what he has given us.",
        Verse: "Luke 2:10 ",
        Date: "24th Dec 2022",
        ImgUrl: `url(${require(`../images/christmas.jpg`)})`,
        ImgPercentage: 60,
        Keywords: "joy, christmas, jesus, shepherds",
        References: [],
        Body: JoyForAll,
        Col: "#9faba2",
      },
      "eternity-beyond": {
        Url: "eternity-beyond",
        Title: "Eternity Beyond",
        Passage: "Matthew 6",
        OneLiner: "For when you lose sight of what matters",
        VerseInfo:
          "But store up for yourselves treasures in heaven, where moths and vermin do not destroy, and where thieves do not break in and steal. For where your treasure is, there your heart will be also.",
        Synopsis:
          "It's important for us to have an eternal perspective. To remember that this life isn't all there is.",
        Verse: "Matthew 6:20-21 ",
        Date: "5th Dec 2022",
        ImgUrl: `url(${require(`../images/space.jpg`)})`,
        ImgPercentage: 60,
        Keywords: "Guidance, Psalm 16, Sovereignty, Guidance",
        References: [],
        Body: GetEternityBeyond,
        Col: "#93a1c4",
      },
      "time-and-chance": {
        Url: "time-and-chance",
        Title: "Time And Chance",
        Passage: "Ecclesiastes 9",
        OneLiner: "For when it all seems meaningless",
        VerseInfo: "",
        Synopsis:
          "When aspects of life often feel random and meaningless we are called to trust and depend on our creator.",
        Verse: "",
        Date: "22nd Oct 2022",
        ImgUrl: `url(${require(`../images/dice.jpg`)})`,
        ImgPercentage: 70,
        Keywords: "Guidance, Psalm 16, Sovereignty, Guidance",
        References: [],
        Body: GetTimeAndChance,
        ImageReference: "https://www.instagram.com/brodie_imagery/",
        ImageReferenceText: "Photo by @brodie_imagery",
        Col: "#bda1c9",
      },
      "pleasant-places": {
        Url: "pleasant-places",
        Title: "Pleasant Places",
        Passage: "Psalm 16",
        OneLiner: "For when you need guidance",
        VerseInfo:
          "You make known to me the path of life; you will fill me with joy in your presence, with eternal pleasures at your right hand.",
        Synopsis:
          "As we go through the ups and downs of life, we can remember that there is a guiding hand leading us through it all.",
        Verse: "Psalm 16:11 ",
        Date: "28th Sep 2022",
        ImgUrl: `url(${require(`../images/pleasant_places.jpg`)})`,
        ImgPercentage: 60,
        Keywords: "Guidance, Psalm 16, Sovereignty, Guidance",
        References: ["Philippians 1:6", "Acts 2:25-28"],
        Body: GetPleasantPlaces,
        Col: "#f7c8df",
      },
      "under-the-fig-tree": {
        Url: "under-the-fig-tree",
        Title: "Under The Fig Tree",
        Passage: "John 1",
        PassageDetailed: "John 1: 43-51",
        OneLiner: "For when you feel unloved",
        VerseInfo:
          "Jesus answered, “I saw you while you were still under the fig tree before Philip called you.”",
        Synopsis:
          "We are fully known and fully loved by the God of the universe. Remembering this gives us rest and security.",
        Verse: "John 1:48",
        Date: "3rd Sep 2022",
        ImgUrl: `url(${require(`../images/fig_tree.jpg`)})`,
        ImgPercentage: 65,
        Keywords: "Known, Loved, Seen, God sees, Fully known, John 1",
        References: [],
        Body: GetUnderTheFigTree,
        Col: "#93adc4",
      },
      "lonely-places": {
        Url: "lonely-places",
        Title: "Lonely Places",
        Passage: "Luke 5",
        OneLiner: "For when you feel lonely",
        VerseInfo: "But Jesus often withdrew to lonely places and prayed.",
        Synopsis:
          "There was a loneliness and isolation that Jesus experienced during his human life. What does it teach us?",
        Verse: "Luke 5:16",
        Date: "29th Aug 2022",
        ImgUrl: `url(${require(`../images/clouds_lonely.jpg`)})`,
        ImgPercentage: 20,
        Keywords: "Shade, Psalm 121, Protection, Guidance",
        References: [
          "Matthew 12:15, Matthew 14:13, Mathew:15:21, Mark 3:7, Luke 5:16, Luke 9:10, John 6:15, John 11:54",
          "John 6:15",
          "Mark 6:31",
          "Matthew 12:14",
        ],
        Body: GetLonelyPlaces,
        Col: "#93adad",
      },
      shade: {
        Url: "shade",
        Title: "Shade",
        Passage: "Psalm 121",
        OneLiner: "For when you need help",
        VerseInfo:
          "The LORD will watch over your coming and going both now and forevermore",
        Synopsis:
          "God gives us shade. Protecting us as he lovingly watches over us day and night",
        Verse: "Psalm  121:8",
        Date: "25th Aug 2022",
        ImgUrl: `url(${require(`../images/meadow.jpg`)})`,
        ImgPercentage: 40,
        Keywords: "Shade, Psalm 121, Protection, Guidance",
        Body: GetShade,
        Col: "#93bda2",
      },
      "streams-of-water": {
        Url: "streams-of-water",
        Title: "Streams Of Water",
        Passage: "Psalm 1",
        OneLiner: "For your need to grow",
        VerseInfo:
          "That person is like a tree planted by streams of water, which yields its fruit in season and whose leaf does not wither— whatever they do prospers.",
        Synopsis:
          "The importance of God's word in our lives is something we quite often miss",
        Verse: "Psalm  1:3",
        Date: "20th Aug 2022",
        ImgUrl: `url(${require(`../images/stream.jpg`)})`,
        ImgPercentage: 40,
        Keywords: "Bible, Psalm 1, God's Word",
        Body: GetStreams,
        Col: "#dbcfa2",
      },
      "an-undeserved-call": {
        Url: "an-undeserved-call",
        Title: "An Undeserved Call",
        Passage: "Mark 2",
        PassageDetailed: "Mark 2: 13-17",
        OneLiner: "For a reminder of His love",
        VerseInfo:
          "It is not the healthy who need a doctor, but the sick. I have not come to call the righteous, but sinners.",
        Synopsis:
          "God's love is not bound by your status or capability. God sees all of you and reaches for you anyway",
        Verse: "Mark 2:17",
        Date: "17th Aug 2022",
        ImgUrl: `url(${require(`../images/dinner_table.jpg`)})`,
        ImgPercentage: 50,
        Keywords: "Levi, Tax collector, God's love",
        Body: GetUndeserved,
        Col: "#d4cba9",
      },
      "waiting-for-the-rain": {
        Url: "waiting-for-the-rain",
        Title: "Waiting For The Rain",
        Passage: "James 5",
        OneLiner: "For when you can't wait",
        VerseInfo:
          "Be patient, then, brothers and sisters, until the Lord’s coming. See how the farmer waits for the land to yield its valuable crop, patiently waiting for the autumn and spring rains",
        Synopsis:
          "Being patient and waiting for God when life is tough and you face hardships",
        Verse: "James 5:7",
        Date: "13th Jun 2022",
        ImgUrl: `url(${require(`../images/rain_leaves.jpg`)})`,
        Keywords: "Patience, Suffering, Trust",
        Body: GetRain,
        Col: "#96ab91",
      },
      "the-skies-above": {
        Url: "the-skies-above",
        Title: "The Skies Above",
        Passage: "Psalm 19",
        OneLiner: "For a reminder of His glory",
        VerseInfo:
          "The heavens declare the glory of God; the skies proclaim the work of his hands.",
        Synopsis:
          "Creation sings to God. Through it we can better learn about his character and who he is",
        Verse: "Psalm 19:1",
        Date: "7th May 2022",
        ImgUrl: `url(${require(`../images/maple_leaves.jpg`)})`,
        Keywords: "Creation, Beauty, Majesty, Declare",
        Body: GetSkies,
        Col: "#b7c49f",
      },
      restlessness: {
        Url: "restlessness",
        Title: "Restlessness",
        Passage: "Psalm 77",
        OneLiner: "For when you can't sleep",
        VerseInfo: "Your ways, God, are holy. What god is as great as our God?",
        Synopsis:
          "In a world that's perpetually  moving how can we step back and find rest?",
        Verse: "Psalm 77:13",
        Date: "27th Apr 2022",
        ImgUrl: `url(${require(`../images/night-drive.jpg`)})`,
        ImgPercentage: 60,
        Keywords: "Anxiety, Restless, Lost, Alone, Faithfulness",
        Body: GetRestlesssness,
        Col: "#a59da8",
      },
      "gods-spiderweb": {
        Url: "gods-spiderweb",
        Title: "God's Spiderweb",
        Passage: "Romans 8",
        OneLiner: "For when it doesn't make sense",
        VerseInfo:
          " I consider that our present sufferings are not worth comparing with the glory that will be revealed in us",
        Synopsis:
          "How can we approach the chaos and unknowns of life when things spiral out of control?",
        Verse: "Romans 8:18",
        Date: "29th Dec 2021",
        ImgUrl: `url(${require(`../images/spiderweb.jpg`)})`,
        ImgPercentage: 70,
        Keywords: "Chaos, Trust, Guidance, Providence",
        Body: GetSpiderweb,
        Col: "#e0d4c3",
      },
    },
  };
};
