import "./VerseLight.css";

export default function VerseLight({ text, verse }) {
  return (
    <div className="verse_text_light">
      {/* <hr className='top_hr'></hr> */}
      {text}
    </div>
  );
}
