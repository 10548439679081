import './Verse.css'

export default function Verse({text, verse}) {
      return (
          <div className='verse_text'>
            {/* <hr className='top_hr'></hr> */}
            {text} - {verse}
            <hr className='bot_hr'></hr>
          </div>
      );
}