import "./Latest.css";

import { getAllData } from "../../data/main";
import { getShorterBook } from "../../helpers/shortenedBooks";
import ReactGA from "react-ga";
import useWindowDimensions from "../../helpers/useWindowDimensions";
import { getBookCol } from "../../helpers/bookColours";

function Latest() {
  //const reader = new FileReader();
  //const text = reader.readAsText(content);
  const { width } = useWindowDimensions();
  const allData = getAllData();

  const handleCardClick = (cardName) => {
    window.location.pathname = `/${cardName}`;
    ReactGA.pageview(window.location.pathname);
  };

  const showShorterBook = width <= 700;

  const DisplayCards = () => {
    return (
      <div class="flex-grid-thirds">
        {Object.keys(allData.data).map((val, index) => {
          const data = allData.data[val];
          let passage = data.Passage;
          if (showShorterBook) {
            passage = getShorterBook(data.Passage);
          }

          return (
            <div class="col" key={val}>
              <div
                class="display-card"
                onClick={() => handleCardClick(data.Url)}
                style={{ cursor: "pointer" }}
              >
                <div
                  class="display-image"
                  style={{ backgroundImage: `${data.ImgUrl}` }}
                ></div>
                <div
                  class="display-passage"
                  style={{
                    backgroundColor: data.Col, // getBookCol(data.Passage),
                    borderColor: data.Col, // getBookCol(data.Passage),
                  }}
                >
                  {passage}
                </div>
                <div class="display-date">{data.Date}</div>

                <div class="display-title">{data.Title}</div>
                <div class="display-desc">{data.Synopsis}</div>
              </div>
            </div>
          );
        })}
      </div>
    );
  };
  return (
    <div class="latest-container">
      <DisplayCards></DisplayCards>
    </div>
  );
}

export default Latest;
