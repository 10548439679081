import { breakLine } from "../../helpers/break";
import VerseLight from "../../components/Verse/VerseLight";

export const GetRestlesssness = (data) => {
  return (
    <div class="d-content">
      Have you ever had one of those nights where you just can’t sleep? You toss
      and turn, but you’re just stuck there in this void, staring at the
      ceiling. Maybe it's the pressure, the stress, or anxiety- whatever it is
      it’s just sticking to you, keeping you awake.
      {breakLine()}
      Psalm 77 gives us a picture of someone who’s in a similar position.
      They’re distressed and restless; crying out to God because they don’t feel
      comforted. Perhaps it's a sin they've committed, or maybe they just feel
      cast out. They feel like God is silent and indifferent to what they’re
      going through.
      <VerseLight
        text="‘Has God forgotten to be merciful? Has he in anger
      withheld his compassion? (v9)’"
      />
      It's so easy to get consumed by the struggles in life and wonder why God
      would treat us like this. There is a temptation to doubt God and doubt if
      he’s truly in control. Or to maybe even think that he’s turned away and
      given up on you.
      {breakLine()}
      However the psalmist then does something unexpected. He starts looking
      back through history. He reminds himself of how God has acted. He starts
      to consume himself with who God is and what he has done.
      <VerseLight
        text="‘You are the God who performs miracles; you display your power among the
        peoples (v14)’"
      />
      He reminds himself of when God rescued his people from Egypt, separating
      the waters of the Red Sea and leading them safely along his path. He
      reminds himself of God’s faithfulness to his people, how despite their
      failings he has continued to remain with them and lead them for his name's
      sake.
      {breakLine()}
      Just as the psalmist looked back at the Red Sea, we too can look back
      through God’s word and see the countless ways he has remained faithful to
      his people. Above all we can look back at the cross. There Jesus
      experienced the distress and restlessness of this life- he knows what it
      feels like right to the end. And so we can come to him with all our
      weariness, with all our burdens. We can find our rest in him.
    </div>
  );
};
