import { breakLine } from "../../helpers/break";
import VerseLight from "../../components/Verse/VerseLight";
import { useState } from "react";
import { referenceLetter } from "../../helpers/referenceLetters";

export const GetPleasantPlaces = (data) => {
  let curIndex = 0;

  const IndexVal = () => {
    const thisIndex = curIndex;
    curIndex += 1;
    return <sup>{referenceLetter(thisIndex)}</sup>;
  };

  return (
    <div class="d-content">
      Psalm 16 describes God's sufficiency in our lives. He is all that we need,
      anything and everything else falls short. The psalmist describes a sense
      of security and stability that comes with this- it directly gives comfort
      and assurance to whatever season of life we might be in. In fact the
      psalmist himself feels so protected and guided by God that he is able to
      express joy in what he has been given and how he has been led.
      <VerseLight text="‘The boundary lines have fallen for me in pleasant places; surely I have a delightful inheritance’ (v6)" />
      Maybe you can relate to that, having enjoyed seasons where you feel God
      has been clearly working in your life. Or perhaps that might be difficult
      to read, maybe you’re in a season where things are hard and you’re not
      quite sure how God is working.
      {breakLine()}I think verse 10 is the key to understanding how we can have
      the same heart as this psalmist no matter where we’re at in life.
      <VerseLight text="‘Because you will not abandon me to the realm of the dead, nor will you let your faithful one see decay’ (v10)" />
      We can have hope that God will finish what he starts in us <IndexVal /> ,
      his providential hand is in full control of our situation. But at the end
      of this life something greater awaits. We will be resurrected to be with
      God in full, we won’t be abandoned. This is only possible because of Jesus
      and his fulfillment of this reality- the only one who never saw decay{" "}
      <IndexVal />.{breakLine()}
      He chose to step down and go through unpleasant places so that we one day
      might reach the ultimate pleasant place, the delightful inheritance of
      being with God for all eternity.
    </div>
  );
};
