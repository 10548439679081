import { breakLine } from "../../helpers/break";
import VerseLight from "../../components/Verse/VerseLight";
import { useState } from "react";
import { referenceLetter } from "../../helpers/referenceLetters";

export const GetShade = (data) => {
  let curIndex = 0;

  const IndexVal = () => {
    const thisIndex = curIndex;
    curIndex += 1;
    return <sup>{referenceLetter(thisIndex)}</sup>;
  };

  return (
    <div class="d-content">
      Psalm 121 says that God is our shade, protecting us from the sun at day
      and the moon at night. It’s this picture of continual guidance as he
      watches over us unfailingly- he doesn’t slumber or sleep. The maker of
      heaven and earth always sees us with everything we’re facing.
      <VerseLight text="‘He will not let your foot slip- he who watches over you will not slumber (v3)’" />
      There is so much comfort we can find in knowing that God watches over us
      like a protective, loving father. It gives us hope that even in our
      difficulties he has intention behind it. But we must remember that this
      was bought at a price. God’s watchful eye over us is possible because he
      chose to stop watching over the Son, to look away as he took the wrath
      that we deserve. Only because of Jesus do we receive the security of God’s
      continual love and protection.
      {breakLine()}
      This leads us to a place of humility and thankfulness. The help God gives
      us is more than we deserve, and yet he gives it to us anyway.
    </div>
  );
};
