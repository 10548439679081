import { breakLine } from "../../helpers/break";
import { BulletList } from "../../components/List/List";
import VerseLight from "../../components/Verse/VerseLight";
import { referenceLetter } from "../../helpers/referenceLetters";

export const GetQuietWaters = (data) => {
  let curIndex = 0;
  const IndexVal = () => {
    const thisIndex = curIndex;
    curIndex += 1;
    return <sup>{referenceLetter(thisIndex)}</sup>;
  };
  return (
    <div class="d-content">
      The desire for control is engraved in all of us. Control over how many
      hours we work, control over what food we eat, control over how we spend
      our money, control over who our friends are, control over who we marry. I
      quite often find myself with this longing to make sure all the details of
      the world around me are able to be maintained and managed, but each time
      I’m quickly reminded just how out of control the world around me is.
      Things get chaotic very quickly, there are too many factors that are
      unpredictable and uncertain. If we try to completely tame our lives and
      make them manageable all by ourselves we are so easily left feeling
      frustrated and defeated.
      {breakLine()}The call for us as Christians is to instead turn to the one
      who is in control. Psalm 23, a psalm perhaps many of us are familiar with,
      depicts the way God is in control of our lives. It describes him as being
      like a shepherd who gently leads his sheep to green pastures and quiet
      waters. A shepherd who guides and comforts.
      {breakLine()}
      When we look at Jesus’ life on earth, we can so clearly see the way he
      lived out the reality of this. One particular example of this is when he
      fed the 5000. The way Mark depicts this event seems to match much of Psalm
      23. After leading the disciples to a quiet place
      <IndexVal /> beside the waters
      <IndexVal />, Jesus notices a crowd following him and sees himself as
      their shepherd
      <IndexVal />. He teaches and guides them
      <IndexVal />. He sits them down on green pastures,
      <IndexVal /> he encourages them to rest
      <IndexVal />. He feeds them and leaves them satisfied
      <IndexVal />.{breakLine()}
      Jesus so clearly cares for his people, he loves them and looks after them.
      I wonder how our lives would change if we trusted God more and saw him as
      that same shepherd towards us. I’m still practically thinking it through,
      so I definitely don’t have all the answers, but here are some things that
      I think might start to change:
      {breakLine()}
      <BulletList
        items={[
          "Perhaps we’d care less about what people think about us and more about how God has us right where he wants us",
          "Perhaps we’d be less inclined to impress people with the events of our own lives, but rather be humble and thankful towards God when we succeed",
          "Perhaps we’d be more willing to turn to him in his word for guidance and to pray to him when things are difficult or don’t make sense",
          "Perhaps we’d trust that even in the moments that seem dark and lonely, that he’s still in control and still our shepherd",
          "Perhaps when we feel defeated or lost, we would more readily turn to the gospel for confidence and assurance",
          "Perhaps we’d be less restless and more satisfied knowing we have all we need in him",
        ]}
      />
      {breakLine()}
      When the crowd followed Jesus, he saw them and had compassion on them.
      Jesus sees us and has compassion towards us- the gospel tells us so. If he
      is your shepherd, then let him lead you. Give up your control and
      self-reliance, and ask him to help you depend on him more and more.
    </div>
  );
};
