import { breakLine } from "../../helpers/break";
import VerseLight from "../../components/Verse/VerseLight";

export const GetAGodWhoSees = (data) => {
  return (
    <div class="d-content">
      The feeling of total abandon is one that I think even the most introverted
      and socially uncomfortable of us would prefer to avoid. The idea of being
      forgotten or overlooked isn’t just undesirable or inconvenient, it’s
      heartbreaking and painful. We so often long to be known, to sit
      comfortably in the presence and company of those who at the very least
      accept us. And in those times where we feel like we have been forgotten or
      left out, we can be tempted to spiral into thoughts that this is
      universally the case- that the world or at least the world around us is
      indifferent to our existence.
      {breakLine()}
      In much of the Old Testament, we find ourselves following the story of
      God’s working amidst a particular people to bring about salvation for the
      world. At the start of this story we meet both Abram and Sarai who play
      quite a pivotal role in the genesis of this salvific plan. God explains
      that through them he will bring about nations of people, and that through
      them all peoples on earth would be blessed (Ch 17:16, Ch 12:3). However
      amidst confusion and doubt, Abram and Sarai decide to go their own route
      and attempt to build this family-nation their own way through their slave
      Hagar.
      {breakLine()}
      Upon conceiving, Hagar is then abused by Sarai and forced to flee. It’s
      important to also know that Hagar is a slave, amidst also being in a time
      where society was not particularly kind to women, and so when she is
      mistreated by her own mistress the source of her residency is usurped. She
      isn’t even a part of God’s covenantal plan. And so she is left with
      nothing, alone and forgotten in the desert.
      {breakLine()}
      Despite all of this an angel appears to her and comforts her, he tells her
      that God has heard her misery and gives her promises of a hopeful future
      (I encourage you to read the full encounter in Genesis 16:7-12). After the
      exchange, Hagar gives us the incredible statement:
      <VerseLight
        text="'You are the God who
        sees me’ - Genesis 16:13"
      />
      She is shown so clearly that God was intimately aware of what was going on
      in her situation, none of it slipped past him. Even though everyone else
      had forgotten her, even though she wasn’t directly a part of God’s
      ultimate salvific plan, she was not ignored. Every bit of suffering, every
      tear, every moment of heartache was seen and heard by him.
    </div>
  );
};
